import React from "react";

export default function ProjectTile(prop) {
  console.log(prop);
  return (
    <div className="projects-tile--content">
      <h3> {prop.chosenProject.title} </h3>

      <h4> DESCRIPTION:</h4>
      <p>{prop.chosenProject.description.fullDescription} </p>

      <h4> TECHNOLOGY:</h4>
      <p>{prop.chosenProject.description.technology} </p>

      <h4> LINKS:</h4>
      <div className="link-box">
        <a 
          href={prop.chosenProject.ServerLink}
          target="_blank"
          >

          <button className="projects-tile--link">
            Play Online
          {/* {prop.chosenProject.ServerLinkText} */}
          </button>  
        </a>
        
        <a 
          href={prop.chosenProject.GHlink}
          target="_blank"
          >
          <button className="projects-tile--link">
            GitHub Repository
          {/* {prop.chosenProject.GHLinkText} */}
          </button>  
        </a>
      </div>

      <div className="projects-tile--buttons">
        <button className="projects-tile--button" onClick={prop.closeButton}>
          <h4>CLOSE</h4>
        </button>
      </div>
    </div>
  );
}
