import React from "react";


export default function Footer() {
    return (
           
    <footer>
      <p className="footer-text">
        <a href="#">Aleksandra Gałach</a> 2023. All rights reserved.
      </p>

    </footer>

     
    )
}