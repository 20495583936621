export default [
	{
		id: 1,
		show: true,
		ServerLinkText: "Play online",
		GHLinkText: "Check out the code on GitHub repository",
		ServerLink: "https://master.d1e4r6j2q5hgil.amplifyapp.com/",
		GHlink: "https://github.com/Rachelkakoderka/classical-art-memory-game-react",
		title: "ClassicalArt Memory Game",
		description: {
			technology: "React, TypeScript",
			inspiration: "my own",
			fullDescription: "I desgined simple memory game where cards are art pieces. I wrote the game logic from scratch in React and Typescript."
		}
	},
	{
		id: 2,
		show: true,
		ServerLinkText: "See online",
		GHLinkText: "Check out the code on GitHub repository",
		ServerLink: "https://www.konradgalach.link",
		GHlink: "https://github.com/Rachelkakoderka/online-store-v1",
		title: "myHatStore",
		description: {
			technology: "React, Typescript, ReduxJS, React-Router",
			inspiration: "my own",
			fullDescription: "An on-line store website that I am currently working on. User can mark products as favourite and add them to cart. Project uses react-router to render different pages: home page, account page and cart. State of the app is managed with react-redux."
			
		}
	},
	{
		id: 3,
		show: true,
		ServerLinkText: "Play online",
		GHLinkText: "Check out the code on GitHub repository",
		ServerLink: "https://master.d2yapddc32ap1k.amplifyapp.com/",
		GHlink: "https://github.com/Rachelkakoderka/quizzical",
		title: "Quizzical",
		description: {
			technology: "React, Typescript",
			inspiration: "my own",
			fullDescription: " A trivia quiz game. The app fetches a set of questions from www.opentdb.com, and then the player is able to mark their answers and check if they were correct. The app shows correct and incorrect answers and counts user's score. Project written in React using TypeScript."
			
		}
	},
	{
		id: 4,
		show: true,
		ServerLinkText: "Play online",
		GHLinkText: "Check out the code on GitHub repository",
		ServerLink: "https://master.d3jlo4o949ca3s.amplifyapp.com/",
		GHlink: "https://github.com/Rachelkakoderka/color-scheme-generator",
		title: "Color Scheme Generator",
		description: {
			technology: "React, Typescript",
			inspiration: "my own",
			fullDescription: "Quick project I made to excercise REST API / fetching data for application. Written in React & Typescript."
			
		}
	},
	{
		id: 5,
		show: true,
		ServerLinkText: "Play online",
		GHLinkText: "Check out the code on GitHub repository",
		GHlink: "https://github.com/Rachelkakoderka/tenzies",
		ServerLink: "https://master.d21cv08cp5k95t.amplifyapp.com",
		title: "Tenzies" ,
		description: {
			technology: "React, Typescript, CSS",
			inspiration: "on-line course",
			fullDescription: "Design and logic of this game was subject of a React course I took on Scrimba.com, but after completing the course I wrote it again locally in Typescript. I also added new features such as: hiding numbers before start, round timer and moves counter.		<br /> App uses react-confetti package for visual effect of winning and nanoid package to generate random keys for child die components."
		}
	},
	{
		id: 6,
		show: true,
		ServerLinkText: "See online",
		GHLinkText: "Check out the code on GitHub repository",
		ServerLink: "https://master.d37b4hs6sho2lc.amplifyapp.com/",
		GHlink: "https://github.com/Rachelkakoderka/taskify-ts",
		title: "Taskify" ,
		description: {
			technology: "React, Typescript, CSS",
			inspiration: "on-line course",
			fullDescription: "I created this to-do-list app to practice writing Typescript in React. Project is based on youtube course: React & TypeScript - Course for Beginners"
		}
	},
	{
		id: 7,
		show: false,
		ServerLinkText: "Play online",
		GHLinkText: "Check out the code on GitHub repository",
		ServerLink: "https://konradgalach.link",
		GHlink: "https://github.com/Rachelkakoderka/ClassicalArt-Memory-Game",
		title: "ClassicalArt Memory Game",
		description: {
			technology: "Vanilla JS, CSS, HTML5",
			inspiration: "my own",
			fullDescription: "Simple memory game with art pieces as cards written in pure Javascript. In the feature I plan to re-write the game in React and add new features such as: keeping best score, different difficulty levels and option to restart the game. You can play it online - just click the link below."
			
		}
	},
		{
		id: 8,
		show: false,
		ServerLinkText: "See online",
		GHLinkText: "Check out the code on GitHub repository",
		GHlink: "https://github.com/Rachelkakoderka/...",
		ServerLink: "https://github.com/Rachelkakoderka/...",
		description: {
			technology: "...",
			inspiration: "my own",
			fullDescription: "..."
			
		}
	},
	

	   
];
